import React, {Fragment, useState} from "react";
import './TabList.scss'
import {TabListProps} from "../../types/TabTypes";

// code from https://blog.logrocket.com/how-to-build-tab-component-react/

const TabList = ({children, activeTabIndex = 0}: TabListProps) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex);
  const handleTabClick = (index: Number) => {
    setActiveTab(index);
  };

  return (
    <Fragment>

      <svg xmlns="http://www.w3.org/2000/svg" className="d-none">
        <symbol id="list" viewBox="0 0 16 16">
          <path fillRule={"evenodd"}
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </symbol>
      </svg>

      <div className="sticky-top flex-md-nowrap shadow-sm bg-white">
        <ul className="navbar-nav flex-row d-md-none">
          <li className="nav-item text-nowrap">
            <button className="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarMenu"
                    aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
              <svg className="bi">
                <use xlinkHref={"#list"}/>
              </svg>
              <span className={"p-2"}>Разделы</span>
            </button>
          </li>
        </ul>
      </div>

      <div className="tabs">
        <div className={"row"}>
          <div className={"sidebar col-md-3 col-lg-2 p-0"}>
            <div className="offcanvas-md offcanvas-end" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="sidebarMenuLabel">Разделы</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu"
                        aria-label="Close"></button>
              </div>
              <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                <nav className="tab-list-wrapper">
                  <ul className="tab-list" role="tablist" aria-orientation="horizontal">
                    {
                      children.map((tab, index) => (
                        <li key={`tab-${index}`}>
                          <button
                            key={`tab-btn-${index}`}
                            data-bs-dismiss="offcanvas"
                            data-bs-target="#sidebarMenu"
                            aria-label="Close"
                            onClick={() => handleTabClick(index)}
                            className={`btn ${
                              activeTab === index && "btn-primary"
                            }`}
                          >{tab.props.label}</button>
                        </li>
                      ))
                    }
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <main className={"col-md-9 ms-sm-auto col-lg-10 px-md-4"}>
            {children[activeTab]}
          </main>
        </div>
      </div>
    </Fragment>
  );
};

export default TabList;