import {ReadGlonas} from "../Commands";
import React, {Fragment, useEffect, useState} from "react";
import {ClockStation} from "../Targets";
import CommonTextControl from "./CommonTextControl";
import {Stack} from "rsuite";
import GpsCoordinatesControl from "./GpsCoordinatesControl";

const GlonasControl = ({executeCommand}) => {
  const [loading, SetLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        const dataClone = structuredClone(data)

        if (dataClone.time.seconds < 59) {
          dataClone.time.seconds = dataClone.time.seconds + 1
        } else {
          dataClone.time.seconds = 0
          if (dataClone.time.minutes < 59) {
            dataClone.time.minutes = dataClone.time.minutes + 1
          } else {
            dataClone.time.minutes = 0
            if (dataClone.time.hours < 23) {
              dataClone.time.hours = dataClone.time.hours + 1
            } else {
              dataClone.time.hours = 0
            }
          }
        }

        dataClone.timeParsed = `${dataClone.time.hours < 10 ? `0${dataClone.time.hours}` : dataClone.time.hours}`
          + `:${dataClone.time.minutes < 10 ? `0${dataClone.time.minutes}` : dataClone.time.minutes}`
          + `:${dataClone.time.seconds < 10 ? `0${dataClone.time.seconds}` : dataClone.time.seconds}`
        setData(dataClone)
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, data?.time]);

  const readParameters = async () => {

    SetLoading(true)
    setIsActive(false)

    try {
      const result = await executeCommand(ClockStation, ReadGlonas, [])

      const utf8decoder = new TextDecoder()
      let buffer = new Uint8Array(result.Params);

      //1. Один байт: признак данных. ‘A’ – данные актуальны, ‘V’ – данные на актуальны, символ ‘N’ – нет данных с приёмника (не подключен или вышел из строя).
      //2. Один байт направления широты: ‘N’-север / ‘S’-юг.
      //3. Шесть байт широты в формате "ГГММмм": ГГ – градусы, ММ – минуты, мм – сотые доли минуты.
      //4. Один байт направления долготы: 'E'-восток / 'W'-запад.
      //5. Семь байт долготы в формате "ГГГММмм": ГГГ – градусы, ММ – минуты, мм – сотые доли минуты.
      //6. Шесть байт время UTC в формате "ЧЧММСС": ЧЧ – часы, ММ – минуты, СС – секунды

      const text = utf8decoder.decode(buffer);
      const newGpsData = {
        isConnected: text[0] !== "N",
        isActual: text[0] === "A",
        latitudeDirection: text[1],
        latitude: text.slice(2, 8),
        longitudeDirection: text[8],
        longitude: text.slice(9, 16),
        time: {
          hours: Number(text.slice(16, 18)),
          minutes: Number(text.slice(18, 20)),
          seconds: Number(text.slice(20, 22))
        },
        timeParsed: `${text.slice(16, 18)}:${text.slice(18, 20)}:${text.slice(20, 22)}`
      };

      if (newGpsData.isConnected && newGpsData.isActual) {
        setIsActive(true)
      }

      setData(newGpsData)
      setInitialized(true)

      return text;
    } finally {
      SetLoading(false)
    }
  }
  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  const renderData = () => {

    if (data === null)
      return

    if (!data.isConnected)
      return <p>Нет данных с приемника</p>;

    return (
      <div className={"row"}>
        {
          data?.isActual
            ? <p>Данные актуальны</p>
            : <p>Данные не актуальны</p>
        }
        <GpsCoordinatesControl longitudeDirection={data.longitudeDirection}
                               longitude={data.longitude}
                               latitudeDirection={data.latitudeDirection}
                               latitude={data.latitude}
        />
        <Stack className={"pt-2"}>
          <CommonTextControl label={"Время по Гринвичу"}
                             initialValue={data.timeParsed}
          />
        </Stack>
      </div>
    )
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col">
          <div className="input-group mb-3 col">
            <button className="btn btn-outline-secondary" type="button" onClick={async () => {
              await readParameters();
            }}>
              Получить
              {spin}
            </button>
          </div>
        </div>
      </div>
      {
        renderData()
      }
    </Fragment>
  )
}

export default GlonasControl;