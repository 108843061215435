import React, {Fragment, useEffect, useRef, useState} from "react";
import RelayControl from "./RelayControl";
import {ClockStation} from "../../Targets";
import {ReadRelayMode, WriteRelayMode} from "../../Commands";
import TimeRelayControl from "./TimeRelayControl";
import GlonassRelayControl from "./GlonassRelayControl";

const RelayListControl = ({executeCommand, isNewVersion}) => {
  const [loading, SetLoading] = useState(false);
  const [initialized, SetInitialized] = useState(false);
  const firstTimeRelayRef = useRef();
  const secondTimeRelayRef = useRef();
  const astronomicRelayRef = useRef();
  const [RelayModeChannelOne, SetRelayModeChannelOne] = useState(-1);
  const [RelayModeChannelTwo, SetRelayModeChannelTwo] = useState(-1);

  useEffect(() => {

    async function fetchData() {
      await readRelayModeCommand()
      await firstTimeRelayRef.current?.refresh();
      await secondTimeRelayRef.current?.refresh();
      await astronomicRelayRef.current?.refresh();
    }

    fetchData();
  }, []);

  const writeRelayModeCommand = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await executeCommand(ClockStation, WriteRelayMode, [RelayModeChannelOne, RelayModeChannelTwo])
    } finally {
      SetLoading(false);
    }
  }

  const readRelayModeCommand = async () => {
    SetLoading(true);

    try {
      const relayStateResult = await executeCommand(ClockStation, ReadRelayMode, [])

      SetRelayModeChannelOne(relayStateResult.Params[0])
      SetRelayModeChannelTwo(relayStateResult.Params[1])

      SetInitialized(true)

    } catch (e) {
      console.error(e)
    } finally {
      SetLoading(false);
    }
  }

  const displayRelayModeBlock = () => {
    return (
      <Fragment>
        <div className="row">
          <div className="col">
            <div className="input-group">
              <button className="btn btn-outline-secondary" type="button" onClick={readRelayModeCommand} disabled={loading}>
                {spin}
                Получить
              </button>
              <button className="btn btn-primary" type="button" onClick={writeRelayModeCommand} disabled={loading}>
                {spin}
                Установить
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <fieldset disabled={!initialized}>
              <RelayControl executeCommand={executeCommand}
                            isNewVersion={isNewVersion}
                            channel={0}
                            relayMode={RelayModeChannelOne}
                            SetRelayMode={SetRelayModeChannelOne}/>
            </fieldset>
          </div>
          <div className="col">
            <fieldset disabled={!initialized}>
              <RelayControl executeCommand={executeCommand}
                            isNewVersion={isNewVersion}
                            channel={1}
                            relayMode={RelayModeChannelTwo}
                            SetRelayMode={SetRelayModeChannelTwo}/>
            </fieldset>
          </div>
        </div>
      </Fragment>
    )
  }

  const displayTimeRelayBlock = () => {
    return (
      <Fragment>
        <hr/>
        <div className="row">
          <div className="row">
            <h3>Реле времени</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <TimeRelayControl channel={0}
                              ref={firstTimeRelayRef}
                              isNewVersion={isNewVersion}
                              executeCommand={executeCommand}/>
          </div>
          {
            isNewVersion ? (
                <div className="col-sm-6">
                  <TimeRelayControl channel={1}
                                    ref={secondTimeRelayRef}
                                    isNewVersion={isNewVersion}
                                    executeCommand={executeCommand}/>
                </div>)
              : null
          }

        </div>
      </Fragment>
    )
  }

  const displayAstronomicRelayBlock = () => {
    return (
      <Fragment>
        <hr/>
        <div className="row">
          <h3>Астрономическое реле</h3>
        </div>
        <div className="row">
          <div className="col">
            <GlonassRelayControl ref={astronomicRelayRef} executeCommand={executeCommand}/>
          </div>
        </div>
      </Fragment>
    )
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      {
        displayRelayModeBlock()
      }
      {
        displayTimeRelayBlock()
      }
      {
        displayAstronomicRelayBlock()
      }
    </Fragment>
  )
}

export default RelayListControl;