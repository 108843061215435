import React, {Fragment} from "react";
import DuskDawnTimeControl from "./DuskDawnTimeControl";

const DuskDawnControl = ({executeCommand}) => {
  return (
    <Fragment>
      <div className="row">
        <h2>Восход / Закат</h2>
      </div>
      <div className={"row"}>
        <div className={"col"}>
          <DuskDawnTimeControl executeCommand={executeCommand}/>
        </div>
      </div>
    </Fragment>
  )
}

export default DuskDawnControl;