import React, {Fragment, useEffect, useState} from "react";
import {ReadImpulse, ReadParameters, WriteChannelMode, WriteImpulseDurationInChannel, WriteTimeInChannelCommand} from "../Commands";
import {ClockStation} from "../Targets";
import {BitArrayToNumber, NumberToBitArray} from "../Converters";
import ChannelControl from "./ChannelControl";
import AdminComponent from "./AdminComponent";
import ChannelDescriptionControl from "./ChannelDescriptionControl";

const ImpulseControl = ({executeCommand, channels = 0, deviceId}) => {
  const voltageDataPosition = 0;
  const amperageDataPosition = 1;
  const arrowDataPositionInParams = 7;
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [voltage, setVoltage] = useState(undefined);
  const [impulseDataList, SetImpulseDataList] = useState([
    {channel: 0, impulse: 0, enabled: false, sync: false, available: true, hours: 0, minutes: 0, amperage: undefined, timeChanged: false},
    {channel: 1, impulse: 0, enabled: false, sync: false, available: true, hours: 0, minutes: 0, amperage: undefined, timeChanged: false},
    {channel: 2, impulse: 0, enabled: false, sync: false, available: true, hours: 0, minutes: 0, amperage: undefined, timeChanged: false},
    {channel: 3, impulse: 0, enabled: false, sync: false, available: true, hours: 0, minutes: 0, amperage: undefined, timeChanged: false}
  ]);
  const [mode, SetMode] = useState(-1);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        updateTime()
      }, 60000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive]);

  useEffect(() => {
    readParameters();
  }, [])

  const writeTimeInChannelCommand = async (channelNumber) => {
    setLoading(true);

    const channel = impulseDataList[channelNumber];

    try {
      await executeCommand(ClockStation, WriteTimeInChannelCommand, [channelNumber + 1, channel.hours, channel.minutes])

      const newImpulseDataList = structuredClone(impulseDataList)
      newImpulseDataList[channelNumber].timeChanged = false
      SetImpulseDataList(newImpulseDataList)
    } finally {
      setLoading(false);
    }
  }

  const readParameters = async () => {
    setLoading(true);

    const newImpulseData = structuredClone(impulseDataList)

    try {
      const resultImpulse = await executeCommand(ClockStation, ReadImpulse, [])

      const arr = NumberToBitArray(resultImpulse.Params[5]);

      resultImpulse.Params.slice(0, 4).map((x, i) => {
        newImpulseData[i].impulse = x
        newImpulseData[i].enabled = !!arr[7 - i]
        newImpulseData[i].sync = !!arr[3 - i]
      })

      const result = await executeCommand(ClockStation, ReadParameters, [])
      const arrowsData = result.Params.slice(arrowDataPositionInParams, arrowDataPositionInParams + 8);
      const amperageData = result.Params.slice(amperageDataPosition, amperageDataPosition + 4);
      newImpulseData.map((x, i) => {
        const position = i * 2;
        const hours = arrowsData[position];
        const minutes = arrowsData[position + 1];
        const amperage = amperageData[i] / 100;
        x.hours = hours;
        x.minutes = minutes;
        x.amperage = amperage;
      })

      setVoltage(result.Params[voltageDataPosition] / 10)

      SetImpulseDataList(newImpulseData)
      SetMode(resultImpulse.Params[4])
      setIsActive(true)
    } finally {
      setLoading(false);
      setInitialized(true)
    }
  }

  const updateTime = async () => {
    setLoading(true);

    const newImpulseData = structuredClone(impulseDataList)

    try {
      const result = await executeCommand(ClockStation, ReadParameters, [])
      const arrowsData = result.Params.slice(arrowDataPositionInParams, arrowDataPositionInParams + 8);
      newImpulseData.map((x, i) => {
        const position = i * 2;
        const hours = arrowsData[position];
        const minutes = arrowsData[position + 1];
        x.hours = hours;
        x.minutes = minutes;
      })

      SetImpulseDataList(newImpulseData)
    } finally {
      setLoading(false);
    }
  }

  const writeImpulseParameters = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const enabledList = impulseDataList.map(x => x.enabled);
      const syncList = impulseDataList.map(x => x.sync);
      const unionData = [...enabledList, ...syncList].reverse();

      const flag = BitArrayToNumber(unionData);

      await executeCommand(ClockStation, WriteImpulseDurationInChannel, impulseDataList.map(x => x.impulse))
      await executeCommand(ClockStation, WriteChannelMode, [mode, flag])
    } finally {
      setLoading(false);
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  const renderControls = () => {
    return (
      <Fragment>
        <div className={"row"}>
          <div className={"col-6"}>
            <h4>Управление времени на часах</h4>
          </div>
          <div className={"col"}>
            <h4>Длительность импульса</h4>
          </div>
          <AdminComponent>
            {() => (
              <div className={"col"}>
                <h4>Синхронизация электронных часов</h4>
              </div>
            )}
          </AdminComponent>
        </div>
        <div className={"row"}>
          <div className={"col"}>
            {
              impulseDataList
                .filter((channelData) => channelData.channel + 1 <= channels)
                .map(x => (
                  <div key={x.channel}>
                    <div className={"row"}>
                      <h4>{x.channel + 1} канал</h4>
                      <fieldset disabled={!initialized}>
                        <ChannelControl channel={x.channel}
                                        hours={x.hours}
                                        minutes={x.minutes}
                                        impulse={x.impulse}
                                        enabled={x.enabled}
                                        amperage={x.amperage}
                                        sync={x.sync}
                                        onChannelChanged={(channel, hours, minutes, impulse, enabled, sync) => {
                                          const newImpulseDataList = structuredClone(impulseDataList)

                                          newImpulseDataList[channel].hours = hours
                                          newImpulseDataList[channel].minutes = minutes
                                          newImpulseDataList[channel].impulse = impulse
                                          newImpulseDataList[channel].enabled = enabled
                                          newImpulseDataList[channel].sync = sync
                                          newImpulseDataList[channel].timeChanged = true

                                          SetImpulseDataList(newImpulseDataList)
                                        }}
                        />
                      </fieldset>
                    </div>
                    {
                      x.timeChanged ?
                        (
                          <div className={"row"}>
                            <div className="input-group">
                              <button className="btn btn-outline-secondary" type="button"
                                      onClick={async () => await writeTimeInChannelCommand(x.channel)} disabled={loading}>
                                Записать
                              </button>
                            </div>
                          </div>
                        )
                        : null
                    }
                    <div className={"row"}>
                      <div className={"input-group"}>
                        <a className="btn" data-bs-toggle="collapse" href={`#collapseExample${x.channel}`} role="button"
                           aria-expanded="false" aria-controls={`collapseExample${x.channel}`}>
                          Детали
                        </a>
                      </div>
                      <div className="collapse" id={`collapseExample${x.channel}`}>
                        <ChannelDescriptionControl channel={x.channel + 1} deviceId={deviceId}/>
                      </div>
                    </div>
                    <hr/>
                  </div>
                ))
            }
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {
        renderControls()
      }
      <div className="row">
        <div className="col">
          <div className="input-group mb-3 col">
            <div className="input-group-text">
              <label className="form-label">Напряжение</label>
            </div>
            <input className={"form-control"} readOnly={true} value={voltage}/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <fieldset disabled={!initialized}>
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label htmlFor={`impulseChannelMode`} className="form-label">Режим</label>
              </div>
              <select className="form-control" id="impulseChannelMode"
                      onChange={event => {
                        SetMode(Number(event.target.value))
                      }}
                      value={mode}>
                <option value={0}>Раздельный</option>
                <option value={1}>Совместный</option>
              </select>
            </div>
          </fieldset>
        </div>
      </div>
      <div className={"row"}>
        <div className="col-md-6 col-sm-6">
          <div className="input-group">
            <fieldset>
              <button className="btn btn-outline-secondary" type="button" onClick={readParameters} disabled={loading}>
                Получить
              </button>
              <button className="btn btn-primary" type="button" onClick={writeImpulseParameters} disabled={loading}>
                Записать
              </button>
            </fieldset>
            <div>
              {spin}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ImpulseControl;