import React from "react";
import {IconButton, InputGroup, MaskedInput, Stack} from "rsuite";
import CopyIcon from '@rsuite/icons/Copy';

const GpsCoordinatesControl = ({latitudeDirection, latitude, longitudeDirection, longitude}) => {

  const handleCopy = async () => {
    try {
      const text =
        `${latitude.substring(0, latitude.length - 4)}.${latitude.substring(latitude.length - 4)} ` +
        `${longitude.substring(0, longitude.length - 4)}.${longitude.substring(longitude.length - 4)}`;
      await navigator.clipboard.writeText(text);
      console.log('Copied to clipboard:', text);
    } catch (error) {
      console.error('Unable to copy to clipboard:', error);
    }
  };

  const maskFunction = (rawValue, config): MaskFunctionType => {
    if (rawValue.replace(/\D/gi, "").length > 6) {
      return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
    }
    return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
  }

  return (
    <Stack className={"pt-2"}>
      <InputGroup>
        <InputGroup inside>
          <InputGroup.Addon
            style={{width: "30px"}}
          >
            {latitudeDirection}
          </InputGroup.Addon>
          <MaskedInput
            readOnly={true}
            value={latitude}
            mask={maskFunction}
            showMask={false}
            guide={false}
            keepCharPositions={false}
            placeholderChar={"_"}
            style={{width: "100px"}}
          />
        </InputGroup>
        <InputGroup inside>
          <InputGroup.Addon
            style={{width: "30px"}}
          >
            {longitudeDirection}
          </InputGroup.Addon>
          <MaskedInput
            readOnly={true}
            value={longitude}
            mask={maskFunction}
            showMask={false}
            guide={false}
            keepCharPositions={false}
            placeholderChar={"_"}
            style={{width: "100px"}}
          />
        </InputGroup>
        <InputGroup.Addon>
          <IconButton onClick={handleCopy} icon={<CopyIcon/>}/>
        </InputGroup.Addon>
      </InputGroup>
    </Stack>
  );
}

export default GpsCoordinatesControl;