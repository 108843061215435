import React, {Fragment, useState} from "react";
import {
  ChannelDisabled, ChannelOff, ChannelOn, ChannelTimeRelay1, ChannelTimeRelay2, ChannelAstronomicRelay,
  OldChannelAstronomicRelay, OldChannelDisabled, OldChannelOff, OldChannelOn, OldChannelTimeRelay
} from "./RelayChannelMode";

const RelayControl = ({isNewVersion, channel, relayMode, SetRelayMode}) => {

  const [loading, SetLoading] = useState(false);

  const states = isNewVersion
    ?
    [
      {text: "Реле отключено (работает по расписанию, если есть)", state: "channelDisabled", id: ChannelDisabled},
      {text: "Реле всегда включено", state: "channelOff", id: ChannelOff},
      {text: "Реле всегда выключено", state: "channelOn", id: ChannelOn},
      {text: "Реле подключено к «Реле времени 1»", state: "channelTimeRelay1", id: ChannelTimeRelay1},
      {text: "Реле подключено к «Реле времени 2»", state: "channelTimeRelay2", id: ChannelTimeRelay2},
      {text: "Реле подключено к «Астрономическому реле»", state: "channelAstronomicRelay", id: ChannelAstronomicRelay},
    ]
    :
    [
      {text: "Реле отключено (работает по расписанию, если есть)", state: "channelDisabled", id: OldChannelDisabled},
      {text: "Реле всегда включено", state: "channelOff", id: OldChannelOff},
      {text: "Реле всегда выключено", state: "channelOn", id: OldChannelOn},
      {text: "Реле подключено к «Реле времени»", state: "channelTimeRelay", id: OldChannelTimeRelay},
      {text: "Реле подключено к «Астрономическому реле»", state: "channelAstronomicRelay", id: OldChannelAstronomicRelay},
    ];

  const handleChange = (event) => {
    SetRelayMode(Number(event.target.value))
  }

  return (
    <Fragment>
      <h6>{channel + 1} канал расписания</h6>
      <fieldset className={"mb-3"} disabled={loading}>
        <div className="row">
          <div className="col">
            {
              states.map((x, index) => {
                return (
                  <div className="form-check" key={index}>
                    <input className="form-check-input"
                           type="radio"
                           name={`channel${channel}`}
                           value={x.id}
                           id={`${x.state}_${x.id}_${channel}`}
                           onChange={handleChange}
                           checked={relayMode === x.id}
                    />
                    <label className="form-check-label" htmlFor={`${x.state}_${x.id}_${channel}`}>
                      {x.text}
                    </label>
                  </div>
                )
              })}
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default RelayControl;