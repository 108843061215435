import React, {Fragment, useEffect, useState} from "react";
import ToggleSwitch from "./Inputs/ToggleSwitch";
import TimeInChannelControl from "./TimeInChannelControl";
import AdminComponent from "./AdminComponent";

const ChannelControl = ({channel, hours, minutes, impulse, enabled, sync, amperage = undefined, onChannelChanged}) => {

  const [localHours, SetLocalHours] = useState(0);
  const [localMinutes, SetLocalMinutes] = useState(0);
  const [localImpulse, SetLocalImpulse] = useState(0);
  const [localEnabled, SetLocalEnabled] = useState(false);
  const [localSync, SetLocalSync] = useState(false);

  const regex = new RegExp(/^\d*$/, 'm');

  useEffect(() => {
    SetLocalImpulse(impulse)
    SetLocalHours(hours)
    SetLocalMinutes(minutes)
    SetLocalEnabled(enabled)
    SetLocalSync(sync)
  }, [hours, minutes, impulse, enabled, sync])

  const onChangeImpulse = (event) => {
    if (!regex.test(event.target.value))
      return;

    const value = Number(event.target.value)

    if (value >= 0 && value <= 255) {
      SetLocalImpulse(event.target.value)
    }
  };

  const onBlurImpulse = (e) => {
    try {
      SetLocalImpulse(Number(localImpulse))
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Fragment>
      <fieldset>
        <div className="row">
          <div className={"col-3"}>
            <TimeInChannelControl channel={channel}
                                  hours={localHours}
                                  minutes={localMinutes}
                                  onTimeChanged={(channel, hours, minutes) => {
                                    SetLocalHours(hours)
                                    SetLocalMinutes(minutes)
                                    onChannelChanged(channel, hours, minutes, localImpulse, localEnabled, localSync)
                                  }}/>
          </div>
          <div className={"col"}>
            <ToggleSwitch id={`channelEnabled${channel}`}
                          small={true}
                          name={""}
                          onChange={(state) => {
                            SetLocalEnabled(state)
                            onChannelChanged(channel, localHours, localMinutes, localImpulse, state, localSync)
                          }}
                          checked={localEnabled}
                          disabled={false}/>
            {
              localEnabled
                ? <span>Включен</span>
                : <span>Выключен</span>
            }
          </div>
          <div className={"col-3"}>
            <input className="form-control"
                   id={`impulseChannel${channel}`}
                   required={true}
                   onChange={onChangeImpulse}
                   onBlur={onBlurImpulse}
                   value={localImpulse}
            />
          </div>
          <div className={"col-3"}>
            <AdminComponent>
              {
                () => (
                  <Fragment>
                    <ToggleSwitch id={`channelSync${channel}`}
                                  small={true}
                                  name={""}
                                  onChange={(state) => {
                                    SetLocalSync(state)
                                    onChannelChanged(channel, localHours, localMinutes, localImpulse, localEnabled, state)
                                  }}
                                  checked={localSync}
                                  disabled={false}/>
                    {
                      localSync
                        ? <span>Включен</span>
                        : <span>Выключен</span>
                    }
                  </Fragment>
                )}
            </AdminComponent>
          </div>
        </div>
        <div className={"row justify-content-end my-3"}>
          <div className={"col-3"}>
            <div className="input-group mb-3 col">
              <div className="input-group-text">
                <label className="form-label">Ток</label>
              </div>
              <input className={"form-control"} readOnly={true} value={amperage}/>
              <span className="input-group-text">A</span>
            </div>
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default ChannelControl;