import React, {Fragment, useEffect, useState} from "react";
import useApiCall from "../hooks/useApiCall";

const ChannelDescriptionControl = ({channel, deviceId}) => {

  const [description, SetDescription] = useState(null);
  const [changed, SetChanged] = useState(false);
  const [initialized, SetInitialized] = useState(false);
  const [loading, SetLoading] = useState(false);
  const modelList = [
    undefined,
    'БК-МЧ-02',
    'БК-МЧ-03',
    'БК-МЧ-03+',
    'БК-МЧ-04',
    'БК-МЧ-С-03',
    'БК-МЧ-01',
    'БК-МЧ-04-С',
    'БК-МФЧ-03',
    'БК-МЧ-04+',
    'БК-МФЧ-С-04',
    'БК-МЧ-С-01',
    'БК-МФЧ-02',
    'БК-МФЧ-01',
    'БК-МФЧ-04',
    'БК-NU-90t'
  ]

  const {fetcher} = useApiCall();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetcher(`/api/mechanisms/${deviceId}/channel/${channel}`);

        if (!response.ok) {
          return;
        }

        const device = await response.json();
        SetDescription(device)
      } finally {
        SetInitialized(true)
      }
    }

    getData()

  }, [deviceId, channel]);

  const updateModel = (e) => {
    let newData = structuredClone(description);
    newData[e.target.name] = e.target.value;
    SetDescription(newData)
    SetChanged(true)
  }

  const updateDataOnServer = async () => {
    SetLoading(true)

    try {
      const response = await fetcher(`/api/mechanisms/${deviceId}/channel/${channel}`, 'PUT', description);
      if (!response.ok) {
        return;
      }

      SetLoading(false);
      SetChanged(false)
    } catch (e) {
      console.error(e)
    }
  }

  //
  return (
    <Fragment>
      <div className="row">
        {
          initialized && description === null ?
            (
              <div className={"input-group"}>
                <button className={"btn btn-outline-success"} type={"button"} onClick={() => {
                  SetDescription({
                    model: undefined,
                    serialNumber: undefined,
                    installationDate: undefined,
                    installationLocation: undefined
                  })
                }}>+
                </button>
              </div>
            ) : null
        }
        {
          initialized && description !== null ?
            (
              <Fragment>
                <div className={"row"}>
                  <div className={"col"}>
                    <div>
                      <span>Модель часового механизма</span>
                      <select className="form-control form-select"
                              name={"model"}
                              onChange={updateModel}
                              value={description.model}>
                        {
                          modelList.map((x, index) => <option key={index} value={x}>{x}</option>)
                        }
                      </select>
                    </div>
                  </div>
                  <div className={"col"}>
                    <div>
                      <span>Серийный номер</span>
                      <input className={"form-control"}
                             name={"serialNumber"}
                             value={description.serialNumber}
                             onChange={updateModel}
                      />
                    </div>
                  </div>
                  <div className={"col"}>
                    <span>Дата установки</span>
                    <input className={"form-control"}
                           type={"date"}
                           name={"installationDate"}
                           value={description.installationDate}
                           onChange={updateModel}
                    />
                  </div>
                </div>
                <div className={"row"}>
                  <div>
                    <div>
                      <span>Место установки</span>
                      <input className={"form-control mb-3"}
                             maxLength={15}
                             name={"installationLocation"}
                             value={description.installationLocation}
                             onChange={updateModel}
                      />
                    </div>
                  </div>
                </div>
                {
                  changed ?
                    (
                      <div className={"row"}>
                        <div className={"col"}>
                          <button className={"btn btn-primary"}
                                  onClick={updateDataOnServer}
                          >Обновить
                          </button>
                        </div>
                      </div>
                    ) : null
                }

              </Fragment>
            ) : null
        }

      </div>
    </Fragment>
  )
}

export default ChannelDescriptionControl;