export const OldChannelDisabled = 0;
export const OldChannelOff = 1;
export const OldChannelOn = 2;
export const OldChannelTimeRelay = 3;
export const OldChannelAstronomicRelay = 4;


export const ChannelDisabled = 0;
export const ChannelOff = 1;
export const ChannelOn = 2;
export const ChannelTimeRelay1 = 3;
export const ChannelTimeRelay2 = 4;
export const ChannelAstronomicRelay = 5;