import React, {Fragment, useState} from "react";
import {
  ReadEthernetState,
} from "../Commands";
import {Esp} from "../Targets";
import IpInput from "./Inputs/IpInput";

const EthernetComponent = ({executeCommand, executeEspCommand}) => {
  const [loading, SetLoading] = useState(false);
  const [enabled, SetEnabled] = useState(false);
  const [ethernetParams, SetEthernetParams] = useState(null);

  const readParameters = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      await readState()
      SetEnabled(true)
    } finally {
      SetLoading(false);
    }
  }

  const readState = async () => {
    try {
      const result = await executeCommand(Esp, ReadEthernetState, [])
      SetEthernetParams(
        {
          Link: result.Link,
          ip: result.EIP.split('.').map(x => Number(x)),
          mask: result.EMASK.split('.').map(x => Number(x)),
          gw: result.EGW.split('.').map(x => Number(x)),
          dns: result.EDNS.split('.').map(x => Number(x))
        }
      )
    } catch (e) {
      console.error(e)
    }
  }

  const handleParamsManualChange = (propertyName, value) => {
    SetEthernetParams({
      ...ethernetParams,
      [propertyName]: value
    })
  }

  const handleParamsCheckboxChange = (e) => {
    SetEthernetParams({
      ...ethernetParams,
      Link: !ethernetParams.Link
    })
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;


  const notDhcpFields = () => {
    return (
      <Fragment>
        <div className={"row"}>
          <div className="col">
            <IpInput labelName={"IP"} octets={ethernetParams?.ip} onValueChanged={(e) => {
              handleParamsManualChange("ip", e.octets)
            }}/>
          </div>
        </div>
        <div className={"row"}>
          <div className="col">
            <IpInput labelName={"Маска"} octets={ethernetParams?.mask} onValueChanged={(e) => {
              handleParamsManualChange("mask", e.octets)
            }}/>
          </div>
        </div>
        <div className={"row"}>
          <div className="col">
            <IpInput labelName={"Шлюз"} octets={ethernetParams?.gw} onValueChanged={(e) => {
              handleParamsManualChange("gw", e.octets)
            }}/>
          </div>
        </div>
        <div className={"row"}>
          <div className="col">
            <IpInput labelName={"DNS"} octets={ethernetParams?.dns} onValueChanged={(e) => {
              handleParamsManualChange("dns", e.octets)
            }}/>
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <fieldset disabled={!enabled}>
        <div className={"row"}>
          <h3>Состояние Ethernet</h3>
        </div>
        <div className={"row"}>
          <div className="col">
            <div className="input-group mb-3">
              <div className="form-check">
                <input type={"checkbox"}
                       className="form-check-input"
                       id={"wParamsDHCP"}
                       checked={ethernetParams?.Link}
                       name={"DHCP"}
                       onChange={handleParamsCheckboxChange}
                />
                <label className="form-check-label" htmlFor={"wParamsDHCP"}>
                  Link
                </label>
              </div>
            </div>
          </div>
        </div>
        {
          notDhcpFields()
        }
      </fieldset>
      <div className={"row"}>
        <div className="col">
          <div className="input-group">
            <fieldset>
              <button className="btn btn-outline-secondary" type="button" onClick={readParameters} disabled={loading}>
                Получить
              </button>
            </fieldset>
            <div>
              {spin}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default EthernetComponent;