import React, {Fragment, useState} from "react";
import {Button, ButtonToolbar} from "rsuite";
import useApiCall from "../../hooks/useApiCall";
import GlonasControl from "../GlonasControl";
import GpsCoordinatesControl from "../GpsCoordinatesControl";

const SynchronizationGpsControl = ({executeCommand, clockStationId}) => {
  const [loading, setLoading] = useState(false);
  const [loadingSavedGpsData, setLoadingSavedGpsData] = useState(false);
  const [storedGpsData, setStoredGpsData] = useState(null);
  const {fetcher} = useApiCall()

  const readSavedGpsData = async () => {
    setLoadingSavedGpsData(true)
    try {
      const response = await fetcher(`/api/navigation/${clockStationId}/saved`)
      if (!response.ok) {
        return;
      }

      const result = await response.json();

      setStoredGpsData(result)

    } catch (error) {

    } finally {
      setLoadingSavedGpsData(false)
    }
  }

  const renderStoredGpsData = (storedGpsData) => {
    if (!storedGpsData.hasData) {
      return (
        <p>Нет сохраненных координат</p>
      )
    }

    return (
      <GpsCoordinatesControl longitudeDirection={storedGpsData.longitudeDirection}
                             longitude={storedGpsData.longitude}
                             latitudeDirection={storedGpsData.latitudeDirection}
                             latitude={storedGpsData.latitude}


      />
    )
  }

  return (
    <Fragment>
      <fieldset disabled={loading}>
        <div className="row">
          <GlonasControl executeCommand={executeCommand}/>
        </div>
        <div className="row">
          <div className={"col"}>
            <p>Сохраненные координаты</p>
            <ButtonToolbar>
              <Button loading={loadingSavedGpsData}
                      appearance={"ghost"}
                      color={"blue"}
                      onClick={readSavedGpsData}
              >
                Получить
              </Button>
            </ButtonToolbar>
          </div>
        </div>
        <div className="row">
          <div className={"col"}>
            {
              storedGpsData && renderStoredGpsData(storedGpsData)
            }
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default SynchronizationGpsControl;