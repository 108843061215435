import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {
  ReadRelay1Command,
  ReadRelay2Command,
  WriteRelay1Command, WriteRelay2Command
} from "../../Commands";
import TimeInput from "../Inputs/TimeInput";
import {ClockStation} from "../../Targets";

const TimeRelayControl = forwardRef(({channel, isNewVersion, executeCommand}, ref) => {
  const [loading, SetLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [onHour, SetOnHour] = useState(0);
  const [onMinute, SetOnMinute] = useState(0);
  const [offHour, SetOffHour] = useState(0);
  const [offMinute, SetOffMinute] = useState(0);

  useImperativeHandle(ref, () => ({
      refresh: async () => {
        await readTimeRelayCommand()
      }
    })
  )

  const readTimeRelayCommand = async () => {
    SetLoading(true);

    try {

      await readData();

      setInitialized(true)

    } catch (e) {
      console.error(e)
    } finally {
      SetLoading(false);
    }
  }

  const readData = async () => {

    let command = ReadRelay1Command;

    if (isNewVersion && channel === 1) {
      command = ReadRelay2Command;
    }

    const result = await executeCommand(ClockStation, command, [])

    SetOnHour(result.Params[1])
    SetOnMinute(result.Params[2])
    SetOffHour(result.Params[3])
    SetOffMinute(result.Params[4])
  }

  const writeTimeRelayCommand = async () => {
    SetLoading(true);


    let command = WriteRelay1Command;

    if (isNewVersion && channel === 1) {
      command = WriteRelay2Command;
    }

    try {
      await executeCommand(ClockStation, command, [0, onHour, onMinute, offHour, offMinute])
    } finally {
      SetLoading(false);
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  const displayTimeRelayBlock = () => {
    return (
      <Fragment>
        <fieldset disabled={!initialized || loading}>
          <div className="row">
            <div className="col-sm-12">
              <span>Время включения</span>
            </div>
            <div className="col-sm-12">
              <TimeInput hours={onHour} minutes={onMinute} onTimeChanged={({hours, minutes}) => {
                SetOnHour(hours);
                SetOnMinute(minutes);
              }}/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <span>Время выключения</span>
            </div>
            <div className="col-sm-12">
              <TimeInput hours={offHour} minutes={offMinute} onTimeChanged={({hours, minutes}) => {
                SetOffHour(hours);
                SetOffMinute(minutes);
              }}/>
            </div>
          </div>
        </fieldset>
        <div className="my-3">
          <div className="input-group">
            <button className="btn btn-outline-secondary" type="button" onClick={readTimeRelayCommand} disabled={loading}>
              {spin}
              Получить
            </button>
            <button className="btn btn-primary" type="button" onClick={writeTimeRelayCommand} disabled={loading}>
              {spin}
              Установить
            </button>
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <h6>{channel + 1} канал расписания</h6>
      {
        displayTimeRelayBlock()
      }
    </Fragment>
  )
})

export default TimeRelayControl;