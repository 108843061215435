import React, {Fragment, useState} from "react";
import {ReadShortCircuitControlState, WriteShortCircuitControlState} from "../Commands";
import {ClockStation} from "../Targets";
import {Radio} from "rsuite";


const ShortCircuitControl = ({executeCommand, channelNumber}) => {

  const [loading, SetLoading] = useState(false);
  const [enabled, SetEnabled] = useState(false);
  const [shortCircuitControlState, setShortCircuitControlState] = useState(null);


  const writeParameters = async (enabled) => {
    SetLoading(true);

    try {
      await executeCommand(ClockStation, WriteShortCircuitControlState, [Number(enabled)])

    } finally {
      SetLoading(false);
    }
  }

  const readParameters = async (e) => {
    e.preventDefault();

    SetLoading(true);

    try {
      const result = await executeCommand(ClockStation, ReadShortCircuitControlState, [])

      setShortCircuitControlState(!!result.Params[0])

      SetEnabled(true)
    } finally {
      SetLoading(false);
    }
  }

  const changeState = async (enabled) => {
    SetLoading(true);

    try {
      await writeParameters(enabled)

      setShortCircuitControlState(enabled)
    } finally {
      SetLoading(false);
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  return (
    <Fragment>
      <div className="input-group mb-3">
        <button className="btn btn-outline-secondary" type="button" onClick={readParameters} disabled={loading}>
          Получить
        </button>
      </div>
      <fieldset disabled={!enabled}>
        <div className="input-group mb-3">
          <button className="btn btn-success" type="button" onClick={() => changeState(true)} disabled={loading}>
            Включить
          </button>
          <button className="btn btn-danger" type="button" onClick={() => changeState(false)} disabled={loading}>
            Выключить
          </button>
        </div>
        {
          loading ? spin : null
        }
        <div>
          <Radio readOnly checked={shortCircuitControlState === true}>Включен</Radio>
          <Radio readOnly checked={shortCircuitControlState === false}>Выключен</Radio>
        </div>
      </fieldset>
    </Fragment>
  );
}

export default ShortCircuitControl;