import {Fragment, useEffect, useState} from "react";
import {Button, ButtonGroup, ButtonToolbar, IconButton, Input, Toggle} from "rsuite";
import useApiCall from "../../hooks/useApiCall";
import {Reload} from "@rsuite/icons";

const SynchronizationNtpControl = ({clockStationId}) => {
  const [loading, setLoading] = useState(false);
  const [ownServerSynchronization, setOwnServerSynchronization] = useState(false);
  const [ntpServer, setNtpServer] = useState("");
  const [espTime, setEspTime] = useState("");
  const {fetcher} = useApiCall()

  useEffect(() => {
    readNtpData()
  }, []);


  const readNtpData = async () => {
    setLoading(true)
    try {
      const response = await fetcher(`/api/Clocks/${clockStationId}/synchronization/ntp`)
      if (!response.ok) {
        return;
      }

      const result = await response.json();

      setNtpServer(result.server);
      setOwnServerSynchronization(!result.ownServer)

    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  const setNtpData = async () => {
    setLoading(true)
    try {
      const response = await fetcher(`/api/Clocks/${clockStationId}/synchronization/ntp`, "POST", {
        ownServer: !ownServerSynchronization,
        server: ntpServer
      })
      if (!response.ok) {
        return;
      }

      readNtpData()
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  const spin = loading
    ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    : null;

  const renderAddressInput = () => {
    return (
      <div className="row my-3">
        <div className={"col"}>
          <Input placeholder="Адрес сервера" disabled={!ownServerSynchronization} value={ntpServer} onChange={setNtpServer}/>
        </div>
      </div>
    )
  }

  const renderEspTime = () => {
    return null;

    return (
      <>
        <div className="row">
          <div className={"col"}>
            <p>Время на блоке удаленного доступа</p>
          </div>
        </div>
        <div className="row my-3">
          <div className={"col"}>
            <ButtonGroup>
              <Button appearance={"ghost"}>Получить</Button>
              <Button appearance={"primary"} color={"blue"}>Обновить с сервера</Button>
            </ButtonGroup>
          </div>
        </div>
        <div className="row">
          <div className={"col"}>
            <p>{espTime}</p>
          </div>
        </div>
      </>
    )
  }

  return (
    <Fragment>
      <fieldset disabled={loading}>
        <div className="row">
          <div className={"col"}>
            <ButtonToolbar>
              <IconButton circle
                          icon={<Reload/>}
                          appearance="subtle"
                          loading={loading}
                          onClick={readNtpData}
              />
              <Button appearance={"primary"}
                      color={"blue"}
                      onClick={setNtpData}
              >
                Записать
              </Button>
            </ButtonToolbar>
          </div>
        </div>
        <div className="row my-3">
          <div className={"col"}>
            <Toggle color={"blue"}
                    checked={ownServerSynchronization}
                    onChange={(state) => {
                      setOwnServerSynchronization(state)
                      if (ownServerSynchronization) {
                        setNtpServer("ntp.bigclock.ru")
                      }
                    }}
            >
              Использовать сервер времени пользователя
            </Toggle>
          </div>
        </div>
        {
          renderAddressInput()
        }
        {
          renderEspTime()
        }
      </fieldset>
    </Fragment>
  )
}

export default SynchronizationNtpControl;