import React, {Fragment} from "react";
import ClockMehPlaceComponent from "./ClockMehPlaceComponent";
import ClockMehModelComponent from "./ClockMehModelComponent";
import ClockMehFabricDateControl from "./ClockMehFabricDateControl";
import ClockMehTehDateControl from "./ClockMehTehDateControl";
import ClockMehVersionControl from "./ClockMehVersionControl";
import ClockMehRepairDateControl from "./ClockMehRepairDateControl";
import ClockMehMinutesLifeTimeControl from "./ClockMehMinutesLifeTimeControl";
import ClockWarrantyControl from "./ClockWarrantyControl";
import ClockMehImpulseComponent from "./ClockMehImpulseComponent";
import ClockMehLifeImpulseComponent from "./ClockMehLifeImpulseComponent";
import ClockMehMeteoComponent from "./ClockMehMeteoComponent";
import DeviceInfoComponent from "../Devices/DeviceInfoComponent";

const ClockComponent = ({executeCommand, deviceId}) => {
  return (
    <Fragment>
      <hr className="h-divider"/>
      <DeviceInfoComponent deviceId={deviceId}/>
      <hr className="h-divider"/>
      {/*<hr className="h-divider"/>*/}
      {/*<ClockMehVersionControl executeCommand={executeCommand}/>*/}
      {/*<hr className="h-divider"/>*/}
      {/*<ClockMehPlaceComponent executeCommand={executeCommand}/>*/}
      {/*<hr className="h-divider"/>*/}
      {/*<ClockMehModelComponent executeCommand={executeCommand}/>*/}
      {/*<hr className="h-divider"/>*/}
      {/*<ClockMehFabricDateControl executeCommand={executeCommand}/>*/}
      <hr className="h-divider"/>
      <ClockMehMeteoComponent executeCommand={executeCommand}/>
      <hr className="h-divider"/>
      <ClockMehTehDateControl executeCommand={executeCommand}/>
      <hr className="h-divider"/>
      <ClockMehRepairDateControl executeCommand={executeCommand}/>
      <hr className="h-divider"/>
      <ClockMehMinutesLifeTimeControl executeCommand={executeCommand}/>
      <hr className="h-divider"/>
      <ClockWarrantyControl executeCommand={executeCommand}/>
      <hr className="h-divider"/>
      <ClockMehImpulseComponent executeCommand={executeCommand}/>
      <hr className="h-divider"/>
      <ClockMehLifeImpulseComponent executeCommand={executeCommand}/>
    </Fragment>
  );
}

export default ClockComponent;