import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import authService from '../services/AuthorizeService';
import Loader from "../components/Loader";
import ClockStationSerialControl from "../components/ClockStationSerialControl";
import DateTimeControl from "../components/DateTimeControl";
import TimeZoneControl from "../components/TimeZoneControl";
import ModelControl from "../components/ModelControl";
import VersionControl from "../components/VersionControl";
import ClockSystemNumberControl from "../components/ClockSystemNumberControl";
import RelayListControl from "../components/Relay/RelayListControl";
import DebugComponent from "../components/DebugComponent";
import GlonasNumberControl from "../components/GlonasNumberControl";
import BlocksControl from "../components/BlocksControl";
import WarrantyControl from "../components/WarrantyControl";
import DemoControl from "../components/DemoControl";
import UpdateControl from "../components/UpdateControl";
import EspVersionControl from "../components/EspVersionControl";
import ChimesControl from "../components/ChimesControl";
import ImpulseControl from "../components/ImpulseControl";
import WifiComponent from "../components/WifiComponent";
import WifiListComponent from "../components/WifiListComponent";
import EthernetComponent from "../components/EthernetComponent";
import GlonasControl from "../components/GlonasControl";
import ShortCircuitControl from "../components/ShortCircuitControl";
import AdminComponent from "../components/AdminComponent";
import TabItem from "../components/Tabs/TabItem";
import TabList from "../components/Tabs/TabList";
import ExternalDevices from "../components/ExternalDevices";
import moment from "moment/moment";
import StatusControl from "../components/StatusControl";
import SynchronizationControl from "../components/Synchronization/SynchronizationControl";
import DuskDawnControl from "../components/DuskDawnControl";

require('moment/locale/ru');

const Device = () => {
  const {deviceId} = useParams();
  const [searchParams] = useSearchParams()
  const debugFlag = searchParams.get("debug")
  const navigate = useNavigate();
  const [loading, SetLoading] = useState(true);
  const [device, SetDevice] = useState(null);
  const [isNewVersion, setIsNewVersion] = useState(undefined);
  const [commandResult, SetCommandResult] = useState("");

  useEffect(() => {
    let interval = null;
    if (!loading) {
      interval = setInterval(async () => {
        const device = await getDeviceInfoAsync();

        SetDevice(device)
      }, 30000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [loading]);

  useEffect(() => {
    const updateDeviceDataAsync = async () => {
      const device = await getDeviceInfoAsync();

      SetDevice(device)

      try {
        const {groups: {major, minor}} = /(?<major>\d+)\.(?<minor>\d+)\.(?<fix>\d+)$/.exec(device.firmwareVersion?.trim())

        const majorVersion = Number(major)
        const minorVersion = Number(minor);

        const isNewVersionDefinition = majorVersion > 11 || (majorVersion === 11 && minorVersion >= 10);

        setIsNewVersion(isNewVersionDefinition)

      } catch (e) {
        console.error(e)
      }
    }

    updateDeviceDataAsync();
  }, [deviceId]);

  const getDeviceInfoAsync = async () => {
    const response = await fetch(`/api/clockstation/${deviceId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
      }
    });

    if (!response.ok) {
      return;
    }

    SetLoading(false);

    return await response.json();
  };

  const BuildBase64StringFromBytes = (parameters) => {
    let binary = '';
    const bytes = new Uint8Array(parameters);

    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return btoa(binary);
  }

  const executeCustomCommand = async (command: Number, parameters) => {
    console.log("Command", command, parameters);

    const base64EncodedString = BuildBase64StringFromBytes(parameters.map(x => Number(x)));

    const requestData = {
      deviceId: deviceId,
      command: command,
      params: base64EncodedString
    };

    const body = JSON.stringify(requestData);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
      },
      body: body
    };

    SetCommandResult("Loading");

    try {
      const response = await fetch('/api/commands/Query', requestOptions);
      const result = await response.json();

      SetCommandResult(JSON.stringify(result));

      return JSON.parse(result.data);
    } catch {
      SetCommandResult("Error");
    }
  }

  const executeCommand = async (device: Text, command: Text, parameters: Array<number>) => {
    console.log("Command", device, command, parameters);

    const base64EncodedString = BuildBase64StringFromBytes(parameters.map(x => Number(x)));

    const requestData = {
      deviceId: deviceId,
      device: device,
      command: command,
      params: base64EncodedString
    };

    const body = JSON.stringify(requestData);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
      },
      body: body
    };

    SetCommandResult("Loading");

    try {
      const response = await fetch('/api/commands/Query', requestOptions);
      const result = await response.json();

      SetCommandResult(JSON.stringify(result));

      return JSON.parse(result.data);
    } catch {
      SetCommandResult("Error");
    }
  }

  const executeDeviceCommand = async (devAdr: Number, devType: Number, command: Text, parameters: Array<number>) => {
    console.log("Device command", devAdr, devType, command, parameters);

    const base64EncodedString = BuildBase64StringFromBytes(parameters.map(x => Number(x)));

    const requestData = {
      deviceId: deviceId,
      device: "Device485",
      command: command,
      params: base64EncodedString,
      devAdr: devAdr,
      devType: devType
    };

    const body = JSON.stringify(requestData);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
      },
      body: body
    };

    SetCommandResult("Loading");

    try {
      const response = await fetch('/api/commands/Query', requestOptions);
      const result = await response.json();

      SetCommandResult(JSON.stringify(result));

      return JSON.parse(result.data);
    } catch {
      SetCommandResult("Error");
    }
  }

  const executeEspCommand = async (device: Text, command: Text, payload: Object) => {
    console.log("Esp command", device, command, payload);

    const requestData = {
      Device: device,
      Command: command,
      ...payload
    };

    const body = JSON.stringify(requestData);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
      },
      body: body
    };

    SetCommandResult("Loading");

    try {
      const response = await fetch(`/api/commands/${deviceId}`, requestOptions);
      const result = await response.json();

      SetCommandResult(JSON.stringify(result));

      return JSON.parse(result.data);
    } catch {
      SetCommandResult("Error");
    }
  }

  const executeNonCommand = async (device: Text, command: Text, parameters: Array<number>) => {
    console.log("Not Command", device, command, parameters);

    const base64EncodedString = BuildBase64StringFromBytes(parameters.map(x => Number(x)));

    const requestData = {
      deviceId: deviceId,
      device: device,
      command: command,
      params: base64EncodedString
    };

    const body = JSON.stringify(requestData);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
      },
      body: body
    };

    SetCommandResult("Loading");

    try {
      await fetch('/api/commands/Command', requestOptions);
    } catch {
      SetCommandResult("Error");
    }
  }

  const updateDevice = async () => {
    const requestData = {
      deviceId: deviceId,
      serialNumber: device.serialNumber,
      clockSerialNumber: device.clockSerialNumber,
      description: device.description,
      channels: device.channels,
      model: device.model,
      firmwareVersion: device.firmwareVersion,
      withMelody: device.withMelody
    };

    const body = JSON.stringify(requestData);

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
      },
      body: body
    };

    SetCommandResult("Update device info execute");

    try {

      const response = await fetch('/api/clockstation', requestOptions);

      SetCommandResult("Update device info execute finished");
    } catch (error) {
      SetCommandResult("Update device info Error: " + error);
    }
  }

  const handleChange = (event) => {
    const deviceNew = structuredClone(device)
    deviceNew.channels = Number(event.target.value);
    SetDevice(deviceNew)
  }

  const render = () => {
    return (
      <Fragment>
        <div className="mb-3">
          <button className="btn btn-link" onClick={() => navigate("/devices", {replace: true})}>К списку устройств
          </button>
        </div>
        {
          loading
            ? <Loader/>
            : content()
        }
      </Fragment>
    )
  }

  const getDebugControl = () => {
    if (debugFlag === null)
      return;

    return <DebugComponent deviceId={deviceId} commandResult={commandResult} setCommandResult={(value) => {
      SetCommandResult(value)
    }}/>
  }

  const renderChimes = () => {
    if (!!!device?.withMelody)
      return;

    return (
      <Fragment>
        <ChimesControl executeCommand={executeCommand}/>
        <hr className="h-divider"/>
      </Fragment>
    )
  }

  const renderDeviceInfo = (device) => {
    if (device === null) {
      return null;
    }

    return (
      <AdminComponent>
        {
          () => (
            <Fragment>
              <p>Номер: <span>{device.serialNumber}</span></p>
              <p>Серийный номер ЧС: <span>{device.clockSerialNumber}</span></p>
              <p>Последняя активность: <span>{moment(device.lastOnlineTime).fromNow()}</span></p>
              <p>Статус: <StatusControl status={device.isConnected ? "online" : "offline"}/></p>
            </Fragment>
          )
        }
      </AdminComponent>
    );
  }

  const content = () => {
    const debugControls = getDebugControl();

    return (
      <Fragment>
        {
          renderDeviceInfo(device)
        }
        <TabList activeTabIndex={0}>
          <TabItem label="Главная">
            <ModelControl executeCustomCommand={executeCustomCommand}
                          initialValue={device?.model}
                          onValueChanged={(newValue) => {
                            const deviceNew = structuredClone(device)
                            deviceNew.model = newValue;
                            SetDevice(deviceNew)
                          }}
            />
            <VersionControl executeCustomCommand={executeCustomCommand}
                            initialValue={device?.firmwareVersion}
                            onValueChanged={(newValue) => {
                              const deviceNew = structuredClone(device)
                              deviceNew.firmwareVersion = newValue;
                              SetDevice(deviceNew)
                            }}

            />
            <ClockSystemNumberControl executeCustomCommand={executeCustomCommand}
                                      initialValue={device?.serialNumber}
                                      onValueChanged={(newValue) => {
                                        const deviceNew = structuredClone(device)
                                        deviceNew.serialNumber = newValue;
                                        SetDevice(deviceNew)
                                      }}
            />
            <ClockStationSerialControl executeCustomCommand={executeCustomCommand}
                                       initialValue={device?.clockSerialNumber}
                                       onValueChanged={(newValue) => {
                                         const deviceNew = structuredClone(device)
                                         deviceNew.clockSerialNumber = newValue;
                                         SetDevice(deviceNew)
                                       }}
            />
            <Fragment>
              <p>Описание</p>
              <div className="input-group  mb-3">
                    <textarea className={'form-control'} value={device?.description} onChange={(e) => {
                      const deviceNew = structuredClone(device)
                      deviceNew.description = e.target.value;
                      SetDevice(deviceNew)
                    }}/>
              </div>
            </Fragment>
            <AdminComponent>
              {
                () => (
                  <Fragment>
                    <Fragment>
                      <div className="input-group mb-3 col">
                        <fieldset disabled={device === null}>
                          <div className="form-check">
                            <input className="form-check-input"
                                   type="radio"
                                   name={`channelCount2`}
                                   value={2}
                                   id={"channelCount2"}
                                   onChange={handleChange}
                                   checked={device?.channels === 2}
                            />
                            <label className="form-check-label" htmlFor={"channelCount2"}>
                              2 Канала
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input"
                                   type="radio"
                                   name={`channelCount4`}
                                   value={4}
                                   id={"channelCount4"}
                                   onChange={handleChange}
                                   checked={device?.channels === 4}
                            />
                            <label className="form-check-label" htmlFor={"channelCount4"}>
                              4 Канала
                            </label>
                          </div>
                        </fieldset>
                      </div>
                    </Fragment>
                    <Fragment>
                      <div className="input-group mb-3 col">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="clockChimesEnabled"
                                 checked={device?.withMelody}
                                 onChange={event => {
                                   const deviceNew = structuredClone(device)
                                   deviceNew.withMelody = !deviceNew.withMelody
                                   SetDevice(deviceNew)
                                 }}
                          />
                          <label className="form-check-label" htmlFor="clockChimesEnabled">
                            Бой
                          </label>
                        </div>
                      </div>
                    </Fragment>
                    <button className="btn btn-dark"
                            onClick={async () => await updateDevice()}>
                      Обновить на сервере
                    </button>
                  </Fragment>)}
            </AdminComponent>
            <hr className="h-divider"/>
            <GlonasNumberControl executeCustomCommand={executeCustomCommand}/>
          </TabItem>
          <TabItem label="Первичные часы">
            <DateTimeControl executeCustomCommand={executeCustomCommand}/>
            <hr className="h-divider"/>
            <TimeZoneControl executeCustomCommand={executeCustomCommand}/>
            <hr className="h-divider"/>
            <h3>Контроль КЗ</h3>
            <ShortCircuitControl executeCommand={executeCommand}/>
            <hr className="h-divider"/>
            <SynchronizationControl executeCommand={executeCommand}
                                    clockStationId={deviceId}
            />
            <hr className="h-divider"/>
            <DuskDawnControl executeCommand={executeCommand}/>
          </TabItem>
          <TabItem label="Вторичные часы">
            <ImpulseControl executeCommand={executeCommand} channels={device?.channels} deviceId={deviceId}/>
          </TabItem>
          <TabItem label="Блокировки">
            <AdminComponent>
              {() => (
                <Fragment>
                  <hr className="h-divider"/>
                  <BlocksControl executeCustomCommand={executeCustomCommand}/>
                </Fragment>
              )}
            </AdminComponent>
          </TabItem>
          <TabItem label="Настройки реле">
            <RelayListControl executeCommand={executeCommand} isNewVersion={isNewVersion}/>
          </TabItem>
          <TabItem label="Настройки боя">
            {renderChimes()}
          </TabItem>
          <TabItem label="Устройства">
            <ExternalDevices executeCommand={executeCommand} executeDeviceCommand={executeDeviceCommand} clockStationId={deviceId}/>
          </TabItem>
          <TabItem label="Гарантия / ТО">
            <EspVersionControl executeCommand={executeCommand}/>
            <hr className="h-divider"/>
            <WarrantyControl executeCustomCommand={executeCustomCommand}/>
            <hr className="h-divider"/>
            <AdminComponent>
              {() => (
                <Fragment>
                  <DemoControl executeCommand={executeCommand}/>
                  <hr className="h-divider"/>
                </Fragment>
              )}
            </AdminComponent>
            <h1>Команды управления ESP</h1>
            <h3>Обновление и питание устройства</h3>
            <UpdateControl executeCommand={executeNonCommand}/>
            <hr className="h-divider"/>
          </TabItem>
          <TabItem label="Настройки сети">
            <WifiComponent executeCommand={executeCommand} executeEspCommand={executeEspCommand}/>
            <WifiListComponent executeCommand={executeCommand} executeEspCommand={executeEspCommand}/>
            <EthernetComponent executeCommand={executeCommand} executeEspCommand={executeEspCommand}/>
          </TabItem>
        </TabList>
      </Fragment>
    );
  }

  return render();
};

export default Device;