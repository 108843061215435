import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import {Layout} from './layouts/Layout';
import 'rsuite/dist/rsuite.min.css';
import './custom.css';
import AuthorizeRoute from "./components/AuthorizeRoute";
import AdminRoute from "./components/AdminRoute";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <Routes>
          {
            AppRoutes.map((route, index) => {
              const {element, requireAuth, requireAdmin, ...rest} = route;
              return (
                <Route key={index} {...rest} element={requireAdmin ?
                  <AdminRoute {...rest} element={element}/>
                  : requireAuth
                    ? <AuthorizeRoute {...rest} element={element}/>
                    : element}
                />
              );
            })
          }
        </Routes>
      </Layout>
    );
  }
}
