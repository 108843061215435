import React, {Fragment, useEffect, useState} from "react";
import {
  Button,
  ButtonToolbar,
  Form,
  IconButton,
  Input,
  Loader,
  Modal,
  Panel,
  Steps,
  Table,
  Dropdown,
  Message,
  Popover,
  Whisper
} from "rsuite";
import MoreIcon from '@rsuite/icons/legacy/More';
import PlusIcon from '@rsuite/icons/Plus';
import {confirm} from '@rsuite/interactions';
import useApiCall from "../hooks/useApiCall";
import AdminComponent from "./AdminComponent";

const {Column, HeaderCell, Cell} = Table;

const AddExternalDevicesWidget = ({clockStationId}) => {


  const [loading, SetLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [selectedDeviceInfo, setSelectedDeviceInfo] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [address, setAddress] = useState(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [deviceError, setDeviceError] = useState(null);
  const {fetcher, apiLoading, error} = useApiCall()

  const columns = [
    {
      key: 'typeName',
      label: 'Тип',
      flexGrow: 1
    },
    {
      key: 'address',
      label: 'Адрес',
      flexGrow: 1
    },
    {
      key: 'type',
      label: 'Тип',
      flexGrow: 1,
      isHidden: true
    },
  ];

  const handleOpen = () => {
    setStep(0)
    setData([])
    setOpen(true);
  }
  const handleClose = async () => {
    SetLoading(true)
    setOpen(false);
    setSelectedDeviceInfo(null)
    setSelectedDevice(null)
    setStep(-1)
  }

  useEffect(() => {
    if (open && clockStationId !== undefined) {
      fetchConnectedDevices()
    }
  }, [clockStationId, open])

  const fetchConnectedDevices = async () => {
    SetLoading(true);
    setData([])
    const response = await fetcher(`api/devices/${clockStationId}/connected`)

    const result = await response.json();

    const dimmers = (result.dimmers ?? []).map(x => ({address: x.address, type: x.type, typeName: "Диммер", status: x.status, id: x.id}));
    const clocks = (result.clocks ?? []).map(x => ({address: x.address, type: x.type, typeName: "Часы", status: x.status, id: x.id}));

    const totalDevices = [...dimmers, ...clocks];

    setData(totalDevices);

    SetLoading(false)
  }

  const fetchDeviceInfo = async (address, type) => {
    try {
      SetLoading(true);
      setDeviceError(null)
      setSelectedDeviceInfo(null)

      const response = await fetcher(`api/devices/${clockStationId}/device/${address}/type/${type}`)

      const result = await response.json();

      setSelectedDeviceInfo(result)
      await setAddress(address)
    } catch (error) {
      console.error(error)
      setDeviceError("Ошибка получения информации по устройству")
    } finally {
      SetLoading(false)
    }
  }

  const renderLoading = () => {
    return <Loader center backdrop content="Loading..."/>;
  };

  const [step, setStep] = React.useState(0);
  const onChange = nextStep => {
    setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);
  const selectDevice = async (device) => {
    await setSelectedDevice(device)
    onNext()
    fetchDeviceInfo(device.address, device.type)
  }

  const onAddDevice = async () => {
    try {
      setDeviceError(null)
      SetLoading(true)

      const body = {
        clockStationId: clockStationId,
        address: selectedDevice.address,
        type: selectedDevice.type,
        Model: selectedDeviceInfo.model,
        serialNumber: selectedDevice.address.toString(),
        place: selectedDeviceInfo.place,
        fabricDate: selectedDeviceInfo.fabricDate,
        firmwareVersion: selectedDeviceInfo.firmwareVersion
      }

      const response = await fetcher(`api/devices/`, "POST", body)

      if (!response.ok) {
        setDeviceError("Произошла ошибка при добавлении устройства (валидация)")
        return;
      }

      const result = await response.json();

      onNext()

    } catch (error) {
      console.error(error)
      setDeviceError("Произошла ошибка при добавлении устройства")
    } finally {
      SetLoading(false)
    }
  }

  const onAddressUpdate = async () => {
    const options = {okButtonText: 'Да', cancelButtonText: 'Нет'};
    const result = await confirm('Вы уверены, что хотите установить новый адрес', options);

    if (result) {
      await updateAddress(selectedDevice.type, selectedDevice.address, address);
    }
  }

  const updateAddress = async (type, oldAddress, newAddress) => {
    try {
      const response = await fetcher(`api/devices/${clockStationId}/device/${oldAddress}/type/${type}/serial/${newAddress}`)
      const result = await response.json();
    } catch (error) {
      console.error(error)
      setDeviceError("Произошла ошибка при смене адреса устройства")
    }
  }

  const onRemoveDevice = async (deviceId) => {
    try {
      setRemoveLoading(true)

      const response = await fetcher(`api/devices/${deviceId}`, "DELETE")

      if (!response.ok) {
        setDeviceError("Произошла ошибка при удалении устройства")
        return;
      }

      fetchConnectedDevices()

    } catch (error) {
      console.error(error)
      setDeviceError("Произошла ошибка при добавлении устройства")
    } finally {
      setRemoveLoading(false)
    }
  }

  const renderMenu = ({onClose, left, top, className}, ref, rowData) => {
    return (
      <Popover ref={ref} className={className} style={{left, top}} full>
        <Dropdown.Menu>
          <Dropdown.Item eventKey={1}>
            <Button appearance={"ghost"}
                    loading={removeLoading}
                    color={"red"}
                    onClick={() => onRemoveDevice(rowData.id)}>Удалить</Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };

  const ActionCell = ({rowData, dataKey, ...props}) => {
    return (
      <Cell style={{padding: '6px'}} {...props} className="link-group">
        {
          rowData.status === 0
            ?
            (
              <Button appearance="link" onClick={() => selectDevice(rowData)}>
                Добавить
              </Button>
            )
            :
            (
              <Whisper placement="autoVerticalStart" trigger="click" speaker={(handlers, ref) => renderMenu(handlers, ref, rowData)}>
                <IconButton appearance="subtle" icon={<MoreIcon/>}/>
              </Whisper>
            )
        }
      </Cell>
    );
  };

  return (
    <Fragment>
      <Modal backdrop={"static"} keyboard={false} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Добавление устройств</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Steps current={step}>
            <Steps.Item/>
            <Steps.Item/>
            <Steps.Item/>
          </Steps>
          <hr/>
          <Panel header={"Список устройств"} hidden={step !== 0}>
            <Table loading={loading} height={300} data={data} renderLoading={renderLoading}>
              {columns
                .filter(x => !!!x.isHidden)
                .map(column => {
                  const {key, label, ...rest} = column;
                  return (
                    <Column {...rest} key={key}>
                      <HeaderCell>{label}</HeaderCell>
                      <Cell dataKey={key}/>
                    </Column>
                  );
                })}
              <Column fixed="right">
                <HeaderCell>Действия</HeaderCell>
                <ActionCell dataKey="id"/>
              </Column>
            </Table>
          </Panel>
          <Panel header={"Информация об устройстве"} hidden={step !== 1}>
            <Form>
              <Form.Group controlId="model">
                <Form.ControlLabel>Модель</Form.ControlLabel>
                <Form.Control name="name" value={selectedDeviceInfo?.model} onChange={value => {
                  const newData = structuredClone(selectedDeviceInfo)
                  newData.model = value
                  setSelectedDeviceInfo(newData)
                }}
                />
              </Form.Group>
              <Form.Group controlId="place">
                <Form.ControlLabel>Место установки</Form.ControlLabel>
                <Form.Control rows={2} value={selectedDeviceInfo?.place} name="textarea" onChange={value => {
                  const newData = structuredClone(selectedDeviceInfo)
                  newData.place = value
                  setSelectedDeviceInfo(newData)
                }}
                />
              </Form.Group>
              <Form.Group controlId="fabricDate">
                <Form.ControlLabel>Дата изготовления</Form.ControlLabel>
                <Form.Control value={selectedDeviceInfo?.fabricDate} name="fabricDate" onChange={value => {
                  const newData = structuredClone(selectedDeviceInfo)
                  newData.fabricDate = value
                  setSelectedDeviceInfo(newData)
                }}
                />
              </Form.Group>
              <Form.Group controlId="firmwareVersion">
                <Form.ControlLabel>Версия прошивки</Form.ControlLabel>
                <Form.Control value={selectedDeviceInfo?.firmwareVersion} name="firmwareVersion"/>
              </Form.Group>
              <Form.Group controlId="address">
                <Form.ControlLabel>Адрес</Form.ControlLabel>
                <Form.Control name="address" value={address} onChange={(e) => {
                  try {
                    const num = Number(e)
                    if (num) {
                      setAddress(num)
                    }
                  } catch (error) {
                    console.error(error)
                  }
                }}/>
              </Form.Group>
              <AdminComponent>
                {
                  () => (
                    <Form.Group>
                      <Button onClick={onAddressUpdate}
                              appearance={"subtle"}
                              color={"orange"}
                              disabled={loading}>
                        Установить новый адрес
                      </Button>
                    </Form.Group>
                  )
                }
              </AdminComponent>
              <Form.Group>
                <ButtonToolbar>
                  <Button onClick={() => fetchDeviceInfo(selectedDevice?.address, selectedDevice?.type)}
                          disabled={loading}
                  >
                    Обновить информацию
                  </Button>
                  <Button onClick={onAddDevice} appearance={"primary"} color={"green"}>
                    Добавить
                  </Button>
                </ButtonToolbar>
              </Form.Group>
              {
                deviceError &&
                <Message showIcon type="error" header="Ошибка">
                  {deviceError}
                </Message>
              }
            </Form>

            {loading && <Loader center content="loading" backdrop={"static"}/>}
          </Panel>
          <Panel hidden={step !== 2}>
            <Message type="success" centered showIcon header="Устройство успешно добавлено!">
              <p>
              </p>
            </Message>
          </Panel>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onPrevious} hidden={step === 0 || step === 2} disabled={loading}>
            Назад
          </Button>
          <Button onClick={handleClose} hidden={step !== 2} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} hidden={step === 2} appearance="subtle">
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>

      <IconButton circle icon={<PlusIcon/>} appearance="default" onClick={handleOpen}/>
    </Fragment>
  )
}

export default AddExternalDevicesWidget;