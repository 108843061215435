import {useState} from "react";
import authService from "../services/AuthorizeService";

const useApiCall = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetcher = async (url, method = "GET", body = null) => {
    const rawBody = body ? JSON.stringify(body) : null

    setLoading(true);
    setError(null);
    try {
      const requestOptions = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
        },
        body: rawBody
      };

      return await fetch(url, requestOptions);
    } finally {
      setLoading(false);
    }
  }

  return {fetcher, loading, error};
}

export default useApiCall;