import React, {Fragment, useEffect, useState} from "react";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import authService from "../services/AuthorizeService";
import Loader from "../components/Loader";

const UserComponent = () => {
  const {userId} = useParams();
  const navigate = useNavigate();
  const [loading, SetLoading] = useState(true);
  const [login, SetLogin] = useState("");
  const [deviceIdToLink, SetDeviceIdToLink] = useState("");
  const [devices, SetDevices] = useState([]);

  useEffect(() => {
    const getDeviceInfoAsync = async () => {
      const response = await fetch(`/api/users/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
        }
      });

      if (!response.ok) {
        return;
      }

      SetLoading(false);

      const userResponse = await response.json();

      SetLogin(userResponse.user.login);
      SetDevices(userResponse.user.devices);
    };

    getDeviceInfoAsync();
  }, [userId]);

  const seedData = async () => {
    const response = await fetch(`/api/users/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
      }
    });

    if (!response.ok) {
      return;
    }

    SetLoading(false);

    const userResponse = await response.json();
    SetDevices(userResponse.user.devices);
  };

  const linkDevice = async (userId, deviceId) => {
    const requestData = {
      deviceId: deviceId,
      userId: userId
    };

    const body = JSON.stringify(requestData);

    try {
      const response = await fetch(`/api/clockstation/link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
        },
        body: body
      });

      if (response.ok) {
        await seedData()
      }
    } catch {
    }
  };

  const unlinkDevice = async (userId, deviceId) => {
    const requestData = {
      deviceId: deviceId,
      userId: userId
    };

    const body = JSON.stringify(requestData);

    try {
      const response = await fetch(`/api/clockstation/link`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
        },
        body: body
      });

      if (response.ok) {
        await seedData()
      }
    } catch {
    }
  };

  const render = () => {
    return (
      <Fragment>
        <div className="mb-3">
          <button className="btn btn-link" onClick={() => navigate("/users", {replace: true})}>К списку пользователей
          </button>
        </div>
        {
          loading
            ? <Loader/>
            : content()
        }
      </Fragment>
    )
  }

  const content = () => {
    return (
      <Fragment>
        <h1>{login}</h1>
        <h3>Устройства</h3>
        <table className="table">
          <thead>
          <tr>
            <td>Устройство</td>
            <td>Серийный номер</td>
            <td>Серийный номер ЧС</td>
            <td>Действия</td>
          </tr>
          </thead>
          <tbody>
          {
            devices.map((device, id) => (
              <tr key={device.id}>
                <td><Link to={`/devices/${device.id}`}>
                  {device.id}
                </Link>
                </td>
                <td>
                  {device.serialNumber}
                </td>
                <td>
                  {device.clockSerialNumber}
                </td>
                <td>
                  <button className={"btn btn-danger"}
                          onClick={async () => {
                            await unlinkDevice(userId, device.id)
                          }}>
                    Удалить
                  </button>
                </td>
              </tr>
            ))
          }
          </tbody>
        </table>
        <div className={"row"}>
          <div className={"col"}>
            <div className={"input-group"}>
              <div className="input-group-text">
                <label className="form-label">ID</label>
              </div>
              <input className={"form-control"}
                     value={deviceIdToLink}
                     onChange={(event) => {
                       event.preventDefault();
                       SetDeviceIdToLink(event.target.value)
                     }}
              />
              <button className={"btn btn-primary"}
                      onClick={async () => {
                        await linkDevice(userId, deviceIdToLink)
                      }}>Добавить
              </button>

            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return render();
};

export default UserComponent;