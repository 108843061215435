import {Fragment, useState} from "react";
import {Button, Toggle} from "rsuite";
import SynchronizationNtpControl from "./SynchronizationNtpControl";
import SynchronizationGpsControl from "./SynchronizationGpsControl";
import useApiCall from "../../hooks/useApiCall";

const SynchronizationControl = ({clockStationId, executeCommand}) => {
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [ntpLoading, setNtpLoading] = useState(false);
  const [ntpSyncEnabled, setNtpSyncEnabled] = useState(false);
  const [gpsLoading, setGpsLoading] = useState(false);
  const [gpsSyncEnabled, setGpsSyncEnabled] = useState(false);

  const {fetcher} = useApiCall()

  const changeGpsState = async (state) => {
    setGpsLoading(true);
    try {
      const response = await fetcher(`/api/Clocks/${clockStationId}/synchronization/gps/state`, "POST", {enabled: state})
      if (!response.ok) {
        return;
      }

      setGpsSyncEnabled(state);
    } catch (error) {
      console.log(error);
    } finally {
      setGpsLoading(false);
    }
  }

  const changeNtpState = async (state) => {
    setNtpLoading(true);
    try {
      const response = await fetcher(`/api/Clocks/${clockStationId}/synchronization/ntp/state`, "POST", {enabled: state})
      if (!response.ok) {
        return;
      }

      setNtpSyncEnabled(state);
    } catch (error) {
      console.log(error);
    } finally {
      setNtpLoading(false);
    }
  }

  const readSyncState = async () => {
    setLoading(true)
    setInitialized(false)
    try {
      const response = await fetcher(`/api/Clocks/${clockStationId}/synchronization`)
      if (!response.ok) {
        return;
      }

      const result = await response.json();

      setNtpSyncEnabled(result.ntpSyncEnabled)
      setGpsSyncEnabled(result.gpsSyncEnabled)

      setInitialized(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  const renderControls = () => {
    if (!initialized) {
      return null;
    }

    return (
      <fieldset disabled={loading}>
        <div className="row">
          <div className="col">
            <h6>Приемник GPS/ГЛОНАСС</h6>
            <div>
              <Toggle color={"green"}
                      loading={gpsLoading}
                      checked={gpsSyncEnabled}
                      onChange={changeGpsState}
              >
                <span>Синхронизация по GPS/ГЛОНАСС {gpsSyncEnabled ? "включена" : "отключена"}</span>
              </Toggle>
            </div>
          </div>
          <div className="col">
            <h6>Сервер времени</h6>
            <Toggle color={"green"}
                    loading={ntpLoading}
                    checked={ntpSyncEnabled}
                    onChange={changeNtpState}
            >
              <span>Синхронизация по NTP {ntpSyncEnabled ? "включена" : "отключена"}</span>
            </Toggle>
          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col">
            {
              gpsSyncEnabled && <SynchronizationGpsControl clockStationId={clockStationId}
                                                           executeCommand={executeCommand}
              />
            }
          </div>
          <div className="col">
            {
              ntpSyncEnabled && <SynchronizationNtpControl clockStationId={clockStationId}/>
            }
          </div>
        </div>
      </fieldset>
    )
  }

  return (
    <Fragment>
      <h2>Синхронизация</h2>
      <div className="row my-3">
        <div className="col">
          <Button onClick={readSyncState} loading={loading}>Получить</Button>
        </div>
      </div>
      {
        renderControls()
      }
    </Fragment>
  )
}

export default SynchronizationControl;