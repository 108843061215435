import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {ReadAstronomicRelay, WriteAstronomicRelay, WriteCoordinates} from "../../Commands";
import {ClockStation} from "../../Targets";
import {InputGroup, InputPicker, MaskedInput} from "rsuite";

const GlonassRelayControl = forwardRef(({executeCommand, channel}, ref) => {
    const [loading, SetLoading] = useState(false);
    const [glonassRelayData, setGlonassRelayData] = useState(null);

    useImperativeHandle(ref, () => ({
            refresh: async () => {
                await readGlonassRelayCommand()
            }
        })
    )

    const readGlonassRelayCommand = async () => {
        SetLoading(true);

        // 1. Один байт 0. 
        // 2. Один байт источника координат. Равен 0 – координаты от ГЛОНАСС приёмника, не равен 0 – координаты от ручного ввода. 
        // 3. Один байт направления широты: ‘N’ север / ‘S’ юг. 
        // 4. Семь байт широты в формате "ГГГгггг": ГГГ – градусы, гггг – десятитысячные доли градуса. 
        // 5. Один байт направления долготы: 'E' восток / 'W' запад. 
        // 6. Семь байт долготы в формате "ГГГгггг": ГГГ – градусы, гггг – десятитысячные доли градуса. 
        // 7. Один байт 0.

        try {
            const result = await executeCommand(ClockStation, ReadAstronomicRelay, [])
            const params = result.Params;
            const utf8decoder = new TextDecoder()
            let buffer = new Uint8Array(result.Params.slice(2, 18));
            const text = utf8decoder.decode(buffer);

            const newGlonassRelayData = {
                isManual: !!params[1],
                latitudeDirection: text[0],
                latitude: text.slice(1, 8),
                longitudeDirection: text[8],
                longitude: text.slice(9, 16),
            }

            setGlonassRelayData(newGlonassRelayData)
        } catch (e) {
            console.error(e)
        } finally {
            SetLoading(false);
        }
    }

    const getCoordinatesString = (data) => {
        let buffer = "";

        buffer += data.latitudeDirection === "N" ? "+" : "-";
        buffer += data.latitude.replace(/\D/gi, "").padStart(7, '0');
        buffer += data.longitudeDirection === "E" ? "+" : "-";
        buffer += data.longitude.replace(/\D/gi, "").padStart(7, '0');

        return buffer;
    }

    const writeGlonassRelayCommand = async () => {
        SetLoading(true);

        try {
            await executeCommand(ClockStation, WriteAstronomicRelay, [0, Number(glonassRelayData.isManual)])
            if (glonassRelayData.isManual) {
                const coordinates = getCoordinatesString(glonassRelayData)

                const utf8Encode = new TextEncoder();
                const bytes = utf8Encode.encode(coordinates + '\n');

                await executeCommand(ClockStation, WriteCoordinates, bytes)
            }
        } finally {
            SetLoading(false);
        }
    }

    const spin = loading
        ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        : null;

    const maskFunction = (rawValue, config) => {
        if (rawValue.replace(/\D/gi, "").length > 6) {
            return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
        }
        return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
    }

    const renderAstronomicRelayCoordinates = () => {
        return (
            <Fragment>
                <div className="row">
                    <fieldset disabled={glonassRelayData === null || !glonassRelayData?.isManual}>
                        <div className={"input-group mb-3"}>
                            <InputGroup>
                                <InputGroup.Addon>Широта</InputGroup.Addon>
                                <InputPicker
                                    data={
                                        [
                                            {label: "N", value: "N"},
                                            {label: "S", value: "S"}
                                        ]
                                    }
                                    defaultValue={"N"}
                                    disabledItemValues={["S"]}
                                    value={glonassRelayData?.latitudeDirection}
                                    onChange={value => {
                                        const newGlonassRelayData = structuredClone(glonassRelayData)
                                        newGlonassRelayData.latitudeDirection = value
                                        setGlonassRelayData(newGlonassRelayData)
                                    }}
                                    style={{width: "100px"}}
                                />
                                <MaskedInput
                                    value={glonassRelayData?.latitude}
                                    mask={maskFunction}
                                    showMask={false}
                                    guide={false}
                                    keepCharPositions={false}
                                    placeholderChar={"_"}
                                    style={{width: 300}}
                                    onChange={value => {
                                        const newGlonassRelayData = structuredClone(glonassRelayData)
                                        newGlonassRelayData.latitude = value
                                        setGlonassRelayData(newGlonassRelayData)
                                    }}
                                />
                            </InputGroup>
                        </div>
                    </fieldset>
                </div>
                <div className="row">
                    <fieldset disabled={glonassRelayData === null || !glonassRelayData?.isManual}>
                        <div className={"input-group mb-3"}>
                            <InputGroup>
                                <InputGroup.Addon>Долгота</InputGroup.Addon>
                                <InputPicker
                                    data={
                                        [
                                            {label: "E", value: "E"},
                                            {label: "W", value: "W"}
                                        ]
                                    }
                                    defaultValue={"E"}
                                    disabledItemValues={["W"]}
                                    value={glonassRelayData?.longitudeDirection}
                                    onChange={value => {
                                        const newGlonassRelayData = structuredClone(glonassRelayData)
                                        newGlonassRelayData.longitudeDirection = value
                                        setGlonassRelayData(newGlonassRelayData)
                                    }}
                                    style={{width: "100px"}}
                                />
                                <MaskedInput
                                    mask={maskFunction}
                                    showMask={false}
                                    guide={false}
                                    keepCharPositions={false}
                                    placeholderChar={"_"}
                                    style={{width: 300}}
                                    value={glonassRelayData?.longitude}
                                    onChange={value => {
                                        const newGlonassRelayData = structuredClone(glonassRelayData)
                                        newGlonassRelayData.longitude = value
                                        setGlonassRelayData(newGlonassRelayData)
                                    }}
                                />
                            </InputGroup>
                        </div>
                    </fieldset>
                </div>
            </Fragment>
        )
    }

    const displayAstronomicRelayBlock = () => {
        return (
            <fieldset>
                <div className="row">
                    <div className="input-group mb-3 col">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id={`glonassManualInput_${channel}`}
                                   checked={glonassRelayData?.isManual}
                                   onChange={event => {
                                       const newGlonassRelayData = structuredClone(glonassRelayData)
                                       newGlonassRelayData.isManual = !newGlonassRelayData.isManual
                                       setGlonassRelayData(newGlonassRelayData)
                                   }}
                                   disabled={glonassRelayData === null}
                                   readOnly={glonassRelayData === null}
                            />
                            <label className="form-check-label" htmlFor={`glonassManualInput_${channel}`}>
                                Ручной ввод
                            </label>
                        </div>
                    </div>
                </div>
                {
                    glonassRelayData?.isManual
                        ? renderAstronomicRelayCoordinates()
                        : null
                }
                <div className="row">
                    <div className="col">
                        <div className="input-group">
                            <button className="btn btn-outline-secondary" type="button"
                                    onClick={readGlonassRelayCommand} disabled={loading}>
                                {spin}
                                Получить
                            </button>
                            <button className="btn btn-primary" type="button" onClick={writeGlonassRelayCommand}
                                    disabled={loading}>
                                {spin}
                                Установить
                            </button>
                        </div>
                    </div>
                </div>
            </fieldset>
        )
    }

    return (
        <Fragment>
            {
                displayAstronomicRelayBlock()
            }
        </Fragment>
    )
})

export default GlonassRelayControl;