import React, {Fragment, useEffect, useState} from "react";
import {
  DisableDimmerRelay,
  EnableDimmerRelay,
  ReadDimmerChannelVoltageRelay,
  ReadDimmerParams,
  SetChannelBrightness,
  SetDimmerChannelsState
} from "../../Commands";
import {BitArrayToNumber, NumberToBitArray} from "../../Converters";
import CommonTextControl from "../CommonTextControl";
import authService from "../../services/AuthorizeService";
import {Notification, useToaster} from 'rsuite';
import DeviceInfoComponent from "../Devices/DeviceInfoComponent";

const DimmerComponent = ({executeCommand, deviceId}) => {

  const seedInitialData = () => ({
    channels: [1, 2, 3, 4, 5, 6].map(channel => ({
      channel: channel,
      description: "",
      enabled: false,
      brightness: 0,
      voltage: undefined
    })),
    enabled: false,
    description: ""
  })

  const toaster = useToaster();
  const [loading, setLoading] = useState(false);
  const [dimmerState, setDimmerState] = useState(seedInitialData());

  const message = (
    <Notification type={"success"} closable>
      <p>Данные записаны</p>
    </Notification>
  );

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(`/api/dimmer/${deviceId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
        }
      });

      if (!response.ok) {
        return;
      }

      setLoading(false);

      const device = await response.json();

      if (device.details) {
        let newDimmerData = structuredClone(dimmerState);
        newDimmerData.channels.map(x => x.description = device.details.channels.find(d => d.channel === x.channel)?.description)
        setDimmerState(newDimmerData)
      }
    }

    getData()

  }, [deviceId]);

  const updateDimmerData = async () => {
    const requestData = {
      channels: dimmerState.channels.map(x => ({channel: x.channel, description: x.description}))
    };

    const body = JSON.stringify(requestData)

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
      },
      body: body
    };

    try {
      const response = await fetch(`/api/dimmer/${deviceId}`, requestOptions);
      if (!response.ok) {
        return;
      }

      toaster.push(message, {placement: "topEnd"})

      setLoading(false);
    } catch (e) {
      console.error(e)
    }
  }

  const readCommand = async (e) => {
    e.preventDefault();

    const startBrightnessIndex = 4;
    const channelsEnableIndex = 10;
    const dimmerEnabledIndex = 11;

    setLoading(true);

    try {
      const result = await executeCommand(ReadDimmerParams, [])
      const resultVoltage = await executeCommand(ReadDimmerChannelVoltageRelay, [])


      const params = result.Params;

      const channelsEnableFlags = NumberToBitArray(result.Params[channelsEnableIndex]).reverse();
      const channelsBrightness = params.slice(startBrightnessIndex, channelsEnableIndex);
      const dimmerEnableState = !!params[dimmerEnabledIndex]
      const channelsVoltage = resultVoltage.Params.slice(2);


      let dimmerChannelsData = []

      for (let channel = 0; channel < 6; channel++) {
        dimmerChannelsData.push({
          ...dimmerState.channels[channel],
          enabled: channelsEnableFlags[channel],
          brightness: channelsBrightness[channel],
          voltage: channelsVoltage[channel] / 10
        })
      }

      const newDimmerState = {
        channels: dimmerChannelsData,
        enabled: dimmerEnableState
      }

      setDimmerState(newDimmerState)
    } finally {
      setLoading(false);
    }
  }

  const writeCommand = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      await executeCommand(SetChannelBrightness, dimmerState.channels.map(x => x.brightness))

      const flags = BitArrayToNumber(dimmerState.channels.map(x => x.enabled).reverse())

      await executeCommand(SetDimmerChannelsState, [flags])
    } finally {
      setLoading(false);
    }
  }

  const changeRelayState = async (enabled) => {
    setLoading(true);

    try {
      const result = await executeCommand(enabled ? EnableDimmerRelay : DisableDimmerRelay, [])

      let newDimmerState = structuredClone(dimmerState)
      newDimmerState.enabled = enabled;
      setDimmerState(newDimmerState)
    } finally {
      setLoading(false);
    }
  }

  const renderData = (data) => {
    if (data === null)
      return;

    return (
      <Fragment>
        <div className="input-group mb-3">
          <button className="btn btn-success" type="button" onClick={() => changeRelayState(true)} disabled={loading}>
            Включить
          </button>
          <button className="btn btn-danger" type="button" onClick={() => changeRelayState(false)} disabled={loading}>
            Выключить
          </button>
        </div>
        <div className="input-group mb-3">
          <div className="form-check">
            <input type={"checkbox"}
                   className="form-check-input"
                   id={"dimmerState"}
                   checked={data.enabled}
                   name={"dimmerState"}
            />
            <label className="form-check-label" htmlFor={"dimmerState"}>
              Включен
            </label>
          </div>
        </div>
        {
          data.channels.map((x, index) => (
            <div key={index}>
              <div className="form-check">
                <input type={"checkbox"}
                       className="form-check-input"
                       id={`dimmerChannelState${index}`}
                       checked={x.enabled}
                       name={"dimmerState"}
                       onChange={() => {
                         let newData = structuredClone(dimmerState)
                         newData.channels[index].enabled = !newData.channels[index].enabled
                         setDimmerState(newData)
                       }}
                />
                <label className="form-check-label" htmlFor={`dimmerChannelState${index}`}>
                  {index + 1} Канал включен
                </label>
              </div>
              <div className="input-group mb-3">
                <label htmlFor={`dimmerChannelBrightness${index}`} className="form-label">Яркость канала {index + 1}</label>
                <input type="range"
                       className="form-range"
                       min="0"
                       max="255"
                       id={`dimmerChannelBrightness${index}`}
                       value={x.brightness}
                       onChange={event => {
                         let newData = structuredClone(dimmerState)
                         newData.channels[index].brightness = Number(event.target.value)
                         setDimmerState(newData)
                       }}
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-text">
                  <label htmlFor={`dimmerChannelVoltage${index}`} className="form-label">Напряжение</label>
                </div>
                <input type="number"
                       className="form-control"
                       id={`dimmerChannelVoltage${index}`}
                       value={x.voltage}
                />
              </div>
              <CommonTextControl label={"Место установки"}
                                 initialValue={x.description}
                                 maxLength={16}
                                 onValueChanged={(text) => {
                                   let newData = structuredClone(dimmerState)
                                   newData.channels[index].description = text
                                   setDimmerState(newData)
                                 }}
              />
            </div>

          ))
        }
      </Fragment>
    )
  }

  return (
    <Fragment>
      <hr className="h-divider"/>
      <DeviceInfoComponent deviceId={deviceId}/>
      <hr className="h-divider"/>
      <div className="input-group mb-3">
        <button className="btn btn-outline-secondary" type="button" onClick={readCommand} disabled={loading}>
          Получить параметры
        </button>
      </div>
      {
        renderData(dimmerState)
      }
      <div className="btn-group mb-3">
        <button className="btn btn-primary" type="button" onClick={writeCommand} disabled={loading}>
          Записать яркость
        </button>
        <button className="btn btn-success" type="button" onClick={updateDimmerData} disabled={loading}>
          Записать место установки
        </button>
      </div>
    </Fragment>
  );
}

export default DimmerComponent;