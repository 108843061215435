import {SearchDevices} from "../Commands";
import React, {Fragment, useEffect, useState} from "react";
import ClockComponent from "./Clock/ClockComponent";
import DimmerComponent from "./Dimmer/DimmerComponent";
import {ClockMehanics, ClockMehanicsN, Dimmer, Esp} from "../Targets";
import {Spinner} from "reactstrap";
import AdminComponent from "./AdminComponent";
import AddExternalDevicesWidget from "./AddExternalDevicesWidget";
import useApiCall from "../hooks/useApiCall";
import {Accordion, Button, ButtonGroup} from "rsuite";
import StatusControl from "./StatusControl";

const ExternalDevices = ({executeCommand, executeDeviceCommand, clockStationId}) => {

  const defaultDeviceType = -1;

  const [loading, SetLoading] = useState(false);
  const [deviceAddresses, setDeviceAddresses] = useState([]);
  const {fetcher, apiLoading, error} = useApiCall()
  const [activeKey, setActiveKey] = useState(-1);

  useEffect(() => {
    readDevicesAddresses()
  }, []);

  const readDevicesAddresses = async () => {
    SetLoading(true);

    try {
      const response = await fetcher(`api/devices/ClockStation/${clockStationId}`)

      const result = await response.json();

      setDevicesResult(result)
    } finally {
      SetLoading(false)
    }
  }

  const searchDevicesAddresses = async () => {
    SetLoading(true);
    setDeviceAddresses([])

    try {
      const result = await executeCommand(Esp, SearchDevices, [])

      setDevicesResult(result)
    } finally {
      SetLoading(false)
    }
  }

  const setDevicesResult = (result) => {
    const dimmerKeyName = "dimmers";
    const clocksKeyName = "clocks";

    const dimmers = (result[dimmerKeyName] ?? []);
    const clocks = (result[clocksKeyName] ?? []);

    const totalResult = [
      ...dimmers.map(x => ({
        text: `Диммер | Адрес: ${x.address} | ${String.fromCharCode(x.type)}`,
        address: x.address,
        type: x.type,
        id: x.id,
        isConnected: x.isConnected
      })),
      ...clocks.map(x => ({
        text: `Часовой механизм | Адрес: ${x.address} | ${String.fromCharCode(x.type)}`,
        address: x.address,
        type: x.type,
        id: x.id,
        isConnected: x.isConnected
      })),
    ]

    setDeviceAddresses(totalResult)
  }

  const renderCurrentDevice = (device) => {
    if (device === null || device.type === defaultDeviceType)
      return;

    if (device.type === Dimmer) {
      return (
        <Fragment>
          <h2>Диммер</h2>
          <DimmerComponent deviceId={device?.id}
                           executeCommand={async (command: Text, parameters: Array<number>) => {
                             return await executeDeviceCommand(device.address, device.type, command, parameters)
                           }}/>
        </Fragment>
      )
    }
    if (device.type === ClockMehanics || device.type === ClockMehanicsN) {
      return (
        <Fragment>
          <h2>Часовой механизм</h2>
          <ClockComponent deviceId={device?.id}
                          executeCommand={async (command: Text, parameters: Array<number>) => {
                            return await executeDeviceCommand(device.address, device.type, command, parameters)
                          }}/>
        </Fragment>
      )
    }
  }

  return (
    <Fragment>
      <AddExternalDevicesWidget clockStationId={clockStationId}/>
      <div className="input-group mb-3">
        {
          loading ?
            (
              <span className={"btn"}>
                <Spinner/>
              </span>
            ) : null
        }
        <button className="btn btn-outline-secondary" type="button" onClick={readDevicesAddresses} disabled={loading}>
          Обновить
        </button>
        <AdminComponent>
          {
            () => (
              <button className="btn btn-outline-danger" type="button" onClick={searchDevicesAddresses} disabled={loading}>
                Поиск
              </button>
            )
          }
        </AdminComponent>
      </div>
      <>
        <ButtonGroup>
          {
            deviceAddresses.map((item, index) => (
              <Button key={index} active={index === activeKey} onClick={() => setActiveKey(index)}>
                <span>Устройство {index + 1}</span>
              </Button>
            ))}
        </ButtonGroup>
        <hr/>
        <Accordion activeKey={activeKey} bordered onSelect={setActiveKey}>
          {
            deviceAddresses.map((device, index) => (
              <Accordion.Panel header={(
                <>
                  <span>{device.text}</span>
                  <StatusControl status={device.isConnected ? "online" : "offline"}/>
                </>
              )} eventKey={index}>
                {
                  renderCurrentDevice(device)
                }
              </Accordion.Panel>
            ))
          }
        </Accordion>
      </>
    </Fragment>
  )
}

export default ExternalDevices;