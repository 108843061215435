import React, {Fragment, useEffect, useState} from "react";
import useApiCall from "../../hooks/useApiCall";
import {Loader, Text} from "rsuite";

const DeviceInfoComponent = ({deviceId}) => {

  const [loading, setLoading] = useState(true);
  const [device, setDevice] = useState(null);
  const {fetcher} = useApiCall()

  useEffect(() => {
    if (deviceId !== null) {
      getData()
    }
  }, [deviceId]);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await fetcher(`api/Devices/${deviceId}`)
      if (!response.ok) {
        return;
      }

      const device = await response.json();

      setDevice(device)

    } catch (error) {
      console.error(error)
    }
    finally {
      setLoading(false);
    }
  }

  const renderDeviceInfo = (device) => {
    if (!device)
      return null;

    return (
      <>
        <Text>Модель: {device.model}</Text>
        <Text>Версия прошивки: {device.firmwareVersion}</Text>
        <Text>Дата производства: {device.fabricDate}</Text>
        <Text>Серийный номер: {device.serialNumber}</Text>
        <Text>Место установки: {device.place}</Text>
      </>
    )
  }

  return (
    <Fragment>
      {
        loading ?
          <Loader size={"md"} content="Загрузка информации об устройстве"/>
          :
          renderDeviceInfo(device)
      }
    </Fragment>
  );
}

export default DeviceInfoComponent;